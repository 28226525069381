// Mixins

@mixin small-mobile {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 640px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: 1920px) {
        @content;
    }
}

@mixin max-width($resolution) {
    @media screen and (max-width: $resolution) {
        @content;
    }
}

// Clearfix

@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

// Rem for font-size

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// Calc view width for different resolutions

@function get-small-mobile($target) {
    $vw-context: (480 * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}

@function get-mobile($target) {
    $vw-context: (640 * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}

@function get-tablet($target) {
    $vw-context: (1024 * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}

@function get-desktop($target) {
    $vw-context: (1920 * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}
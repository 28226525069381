@include desktop {
    header {
        &.page-header {
            .inner-wrapper {
                section {
                    &.header-section {
                        ul {
                            &.main-nav {
                                padding: 0 20px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include tablet {
    header {
        &.page-header {
            height: get-tablet(92px);

            .inner-wrapper {
                section {
                    &.header-section {
                        .logo {
                            width: get-tablet(423px);
                            height: get-tablet(92px);

                            a {
                                background-size: get-tablet(423px) get-tablet(135px);
                                width: get-tablet(423px);
                                height: get-tablet(135px);
                            }
                        }

                        ul {
                            display: none;

                            &.main-nav {
                                padding: 0;

                                &.opened {
                                    top: get-tablet(92px);

                                    li {
                                        a {
                                            padding: get-tablet(20px) 0;
                                        }
                                    }
                                }
                            }
                        }

                        .buttons {
                            display: block;
                            padding: 0 get-tablet(50px) 0 0;

                            .hamburger {

                                .hamburger-1,
                                .hamburger-2,
                                .hamburger-3 {
                                    width: get-tablet(54px);
                                    height: get-tablet(8px);
                                    border-radius: get-tablet(5px);
                                }

                                .hamburger-2 {
                                    margin: get-tablet(8px) 0;
                                }
                            }

                            .close {
                                .hide {
                                    width: get-tablet(54px);
                                    height: get-tablet(40px);

                                    &:before,
                                    &:after {
                                        height: get-tablet(46px);
                                        width: get-tablet(6px);
                                        top: get-tablet(-3px);
                                        left: get-tablet(24px);
                                        border-radius: get-tablet(5px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    header {
        &.page-header {
            .inner-wrapper {
                section {
                    &.header-section {
                        ul {
                            &.main-nav {
                                &.opened {
                                    height: get-mobile(450px);

                                    li {
                                        a {
                                            font-size: get-mobile(35px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
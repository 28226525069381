// Colors

$white: #ffffff;
$black: #000000;
$gray: #1f1f1f;
$gray-2: #24261f;
$gray-3: #d3d3d3;
$orange: #f5821f;
$orange-2: #f07d00;
$red: #e62936;
$red-2: #961b23;
$red-3: #6e0b12;
$green: #4b541b;

// Fonts

$font-base: 'proxima_nova_condensedregular', sans-serif;
$font-base-l: 'proxima_nova_condensedlight', sans-serif;
$font-base-b: 'proxima_nova_condensedblack', sans-serif;
$font-secondary: 'IcoMoon', sans-serif;

@font-face {
    font-family: 'proxima_nova_condensedblack';
    src: url('../fonts/ProximaNova/proximanovacond-black-webfont.eot');
    src: url('../fonts/ProximaNova/proximanovacond-black-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova/proximanovacond-black-webfont.woff2') format('woff2'),
        url('../fonts/ProximaNova/proximanovacond-black-webfont.woff') format('woff'),
        url('../fonts/ProximaNova/proximanovacond-black-webfont.ttf') format('truetype'),
        url('../fonts/ProximaNova/proximanovacond-black-webfont.svg#proxima_nova_condensedblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_condensedlight';
    src: url('../fonts/ProximaNova/proximanovacond-light-webfont.eot');
    src: url('../fonts/ProximaNova/proximanovacond-light-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova/proximanovacond-light-webfont.woff2') format('woff2'),
        url('../fonts/ProximaNova/proximanovacond-light-webfont.woff') format('woff'),
        url('../fonts/ProximaNova/proximanovacond-light-webfont.ttf') format('truetype'),
        url('../fonts/ProximaNova/proximanovacond-light-webfont.svg#proxima_nova_condensedlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_condensedregular';
    src: url('../fonts/ProximaNova/proximanovacond-regular-webfont.eot');
    src: url('../fonts/ProximaNova/proximanovacond-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova/proximanovacond-regular-webfont.woff2') format('woff2'),
        url('../fonts/ProximaNova/proximanovacond-regular-webfont.woff') format('woff'),
        url('../fonts/ProximaNova/proximanovacond-regular-webfont.ttf') format('truetype'),
        url('../fonts/ProximaNova/proximanovacond-regular-webfont.svg#proxima_nova_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IcoMoon';
    src: url('../fonts/IcoMoon/icomoon.eot?fyu1zs');
    src: url('../fonts/IcoMoon/icomoon.eot?fyu1zs#iefix') format('embedded-opentype'),
        url('../fonts/IcoMoon/icomoon.ttf?fyu1zs') format('truetype'),
        url('../fonts/IcoMoon/icomoon.woff?fyu1zs') format('woff'),
        url('../fonts/IcoMoon/icomoon.svg?fyu1zs#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: $font-secondary !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ornament-big:before {
    content: "\e903";
}

.icon-ornament:before {
    content: "\e902";
}

.icon-instagram:before {
    content: "\e901";
}

.icon-facebook:before {
    content: "\e900";
}
footer {
    &.page-footer {
        background-color: $orange;

        .inner-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            padding: 20px 55px;

            .logo {
                width: 141px;
                height: 20px;

                a {
                    display: block;
                    background: url('../images/logo-nedelya-footer.png') center center no-repeat;
                    background-size: 141px 20px;
                    width: 141px;
                    height: 20px;
                }
            }

            .terms {
                a {
                    color: $white;
                    text-align: center;
                }
            }

            .socials {
                width: 141px;

                ul {
                    list-style: none;
                    width: 66px;
                    margin: 0 0 0 auto;

                    li {
                        display: inline-block;
                        margin: 0 5px;
                        height: 29px;

                        &:first-child {
                            margin: 0 5px 0 0;
                        }

                        &:last-child {
                            margin: 0 0 0 5px;
                        }

                        a {
                            @include font-size(26px);

                            color: $white;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
html,
body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

body {
    @include font-size(18px);

    background-color: $white;
    color: $gray;
    font-weight: 300;
    font-family: $font-base;
    line-height: 1.2;
    position: relative;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

:focus {
    outline: none;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    margin: 0;
}

p {
    margin: 0;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin: 0;
}

.inner-wrapper {
    max-width: 1920px;
    margin: 0 auto;
}

.shop-list-hidden {
    display: none;
}

.slider-controls {
    @include clearfix;

    max-width: 50px;
    float: left;
}

.slider-control {
    width: 50px;
    height: 50px;
    display: block;
    transition: all .3s;
}

.next-slide {
    background: url("../images/icon-arrow-red-down.png") center center no-repeat;
    background-size: 100%;
    margin-top: 10px;

    &:hover {
        opacity: 0.5;
    }
}

.prev-slide {
    background: url("../images/icon-arrow-red-up.png") center center no-repeat;
    background-size: 100%;

    &:hover {
        opacity: 0.5;
    }
}

.shop-wrapper {
    float: right;
    width: calc(100% - 85px);
    max-height: 400px;
}

.shop-list-hidden {
    display: none;
}

.shop-box {
    .title {
        @include font-size(25px);

        font-family: $font-base-b;
        text-transform: uppercase;
        text-decoration: none;
        margin-bottom: 14px;
        display: inline-block;
    }

    p {
        @include font-size(20px);

        margin-top: 12px;
    }

    a {
        text-decoration: underline;
    }
}

.select2-container--default .select2-selection--single {
    border-radius: 25px;
    border: 1px solid #d3d3d3;
    height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $black;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
    font-size: 18px;
    padding-left: 29px;
    padding-right: 63px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
    width: 63px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: $red transparent transparent transparent;
    border-width: 8px 7px 0 7px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $red transparent;
    border-width: 0 7px 8px 7px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: white;
    color: $red;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
}

.select2-dropdown {
    border-radius: 25px;
    border: 1px solid $gray-3;
}

.select2-results__option {
    padding-left: 29px;
    font-size: 18px;
}

.select2-container--default .select2-results>.select2-results__options {
    padding-bottom: 10px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: white;
    color: gray;
}

.view-on-map {
    margin-right: 10px;
}
@include desktop {
    main {
        &.content {
            section {
                &.top {
                    height: get-desktop(1080px);
                    background-size: cover;

                    .inner-wrapper {
                        padding: 0 get-desktop(50px);

                        h1 {
                            font-size: get-desktop(60px);
                            text-shadow: get-desktop(2px) get-desktop(3px) 0 rgba($red-3, 0.72);
                            padding: get-desktop(50px) 0;

                            &:before {
                                font-size: get-desktop(40px);
                            }

                            &:after {
                                font-size: get-desktop(40px);
                            }
                        }

                        a {
                            background-size: get-desktop(56px) get-desktop(35px);
                            width: get-desktop(56px);
                            height: get-desktop(35px);
                            margin: get-desktop(30px) auto get-desktop(20px) auto;
                        }
                    }
                }

                &.amount {
                    padding: get-desktop(53px) 0 get-desktop(42px) 0;

                    h3 {
                        font-size: get-desktop(80px);
                        max-width: get-desktop(590px);
                        padding: 0 0 get-desktop(50px) 0;

                        &:after {
                            font-size: get-desktop(40px);
                        }

                        span {
                            font-size: get-desktop(35px);
                        }
                    }

                    h4 {
                        font-size: get-desktop(24px);
                    }
                }

                &.description {
                    .inner-wrapper {
                        padding: 0 get-desktop(50px);
                    }
                }

                &.participate {
                    background-size: get-desktop(1920px) get-desktop(613px);

                    .inner-wrapper {
                        padding: 0 get-desktop(50px) get-desktop(341px) get-desktop(50px);
                    }
                }

                &.about {
                    .inner-wrapper {
                        padding: get-desktop(90px) 0;

                        .flex {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            align-items: center;
                            align-content: center;

                            .image {
                                width: 100%;
                                max-width: get-desktop(960px);

                                img {
                                    width: 100%;
                                    display: block;
                                }
                            }

                            .content {
                                width: 100%;
                                max-width: get-desktop(880px);
                                padding: 0 get-desktop(150px) 0 0;
                            }
                        }
                    }
                }

                &.stores {
                    .inner-wrapper {
                        padding: get-desktop(90px) 0 get-desktop(30px) 0;

                        .flex {
                            padding: get-desktop(45px) 0 0 0;

                            .map {
                                max-width: get-desktop(960px);

                                #g-map {
                                    height: get-desktop(642px);
                                }
                            }

                            .content {
                                max-width: get-desktop(880px);
                                padding: 0 get-desktop(150px) 0 0;

                                .container {
                                    margin: get-desktop(45px) 0 0 0;
                                    height: get-desktop(400px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include tablet {
    main {
        &.content {
            section {
                &.top {
                    height: get-tablet(1136px);
                    background: url('../images/top-background-tablet.jpg') bottom center no-repeat;
                    background-size: get-tablet(1024px) get-tablet(1819px);

                    .inner-wrapper {
                        max-width: get-tablet(660px);
                        padding: 0 get-tablet(50px);

                        h1 {
                            font-size: get-tablet(50px);
                            text-shadow: get-tablet(2px) get-tablet(3px) 0 rgba($red-3, 0.72);
                            padding: get-tablet(50px) 0;
                            max-width: get-tablet(660px);

                            &:before {
                                font-size: get-tablet(40px);
                            }

                            &:after {
                                font-size: get-tablet(40px);
                            }
                        }

                        h2 {
                            font-size: get-tablet(35px);
                            font-weight: 400;
                            font-family: $font-base-b;
                            padding: get-tablet(25px) 0 0 0;
                            max-width: get-tablet(350px);
                        }

                        a {
                            background: url('../images/icon-arrow-header-white.png') center center no-repeat;
                            background-size: get-tablet(126px) get-tablet(70px);
                            width: get-tablet(126px);
                            height: get-tablet(70px);
                            margin: get-tablet(30px) auto get-tablet(-30px) auto;
                        }
                    }
                }

                &.amount {
                    padding: 53px 0 42px 0;

                    h3 {
                        @include font-size(80px);

                        max-width: 100%;
                        padding: 0 0 50px 0;

                        &:after {
                            @include font-size(40px);
                        }

                        span {
                            @include font-size(35px);
                        }
                    }

                    h4 {
                        @include font-size(24px);
                    }
                }

                &.description {
                    .inner-wrapper {
                        max-width: get-tablet(960px);
                        padding: 0 get-tablet(50px);

                        p {
                            max-width: get-tablet(860px);
                            padding: get-tablet(80px) 0 get-tablet(100px) 0;
                        }
                    }
                }

                &.participate {
                    background: url('../images/participate-background.png') bottom center no-repeat;
                    background-size: get-tablet(1920px) get-tablet(613px);

                    .inner-wrapper {
                        max-width: 100%;
                        padding: 0 get-tablet(50px) get-tablet(341px) get-tablet(50px);

                        h3 {
                            @include font-size(60px);
                        }

                        .rows {
                            justify-content: space-between;
                            padding: get-tablet(50px) 0 get-tablet(100px) 0;

                            .row {
                                @include font-size(60px);

                                max-width: 100%;
                                margin: 100px 0 0 0;

                                &:first-child {
                                    margin: 0;
                                }

                                .image {
                                    img {
                                        max-width: 100%;
                                    }
                                }

                                .description {
                                    @include font-size(35px);
                                }
                            }
                        }

                        a {
                            @include font-size(36px);

                            width: 467px;
                            height: 90px;
                            border-radius: 45px;
                            padding: 26px 0;
                        }
                    }
                }

                &.about {
                    .inner-wrapper {
                        padding: get-tablet(90px) 0 0 0;

                        .flex {
                            justify-content: space-around;

                            .image {
                                max-width: 100%;
                                order: 2;
                                margin: get-tablet(100px) 0 0 0;
                            }

                            .content {
                                max-width: 100%;
                                padding: 0 get-tablet(50px);

                                h3 {
                                    @include font-size(60px);
                                }

                                p {
                                    @include font-size(35px);
                                }
                            }
                        }
                    }
                }

                &.stores {
                    .inner-wrapper {
                        padding: get-tablet(90px) 0 get-tablet(30px) 0;

                        h3 {
                            @include font-size(60px);

                            white-space: normal;
                            width: 100%;
                            max-width: 610px;
                            margin: 0 auto;
                        }

                        .flex {
                            justify-content: space-around;
                            padding: 45px 0 0 0;

                            .map {
                                max-width: get-tablet(960px);
                                margin: 0 auto get-tablet(50px) auto;

                                #g-map {
                                    height: get-tablet(642px);
                                }
                            }

                            .content {
                                max-width: get-tablet(960px);
                                padding: 0;

                                .container {
                                    margin: get-tablet(45px) 0 0 0;
                                    height: auto;

                                    .slider-controls {
                                        @include clearfix;

                                        max-width: 100%;
                                        margin: 0 0 get-tablet(45px) 0;
                                        float: initial;

                                        a {
                                            float: left;
                                        }

                                        .slider-control {
                                            width: get-tablet(75px);
                                            height: get-tablet(75px);
                                        }

                                        .next-slide {
                                            background: url("../images/icon-arrow-red-right.png") center center no-repeat;
                                            background-size: get-tablet(75px) get-tablet(75px);
                                            margin: 0 0 0 get-tablet(20px);
                                        }

                                        .prev-slide {
                                            background: url("../images/icon-arrow-red-left.png") center center no-repeat;
                                            background-size: get-tablet(75px) get-tablet(75px);
                                        }
                                    }

                                    .shop-wrapper {
                                        float: initial;
                                        width: 100%;
                                        max-height: 260px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    main {
        &.content {
            section {
                &.top {
                    height: get-mobile(1136px);
                    background: url('../images/top-background-mobile.jpg') center center no-repeat;
                    background-size: get-mobile(640px) get-mobile(1136px);

                    .inner-wrapper {
                        max-width: 100%;
                        padding: 0 get-mobile(50px);

                        h1 {
                            font-size: get-mobile(48px);
                            text-shadow: get-mobile(2px) get-mobile(3px) 0 rgba($red-3, 0.72);
                            padding: get-mobile(50px) 0;
                            max-width: 100%;
                        }

                        h2 {
                            font-size: get-mobile(35px);
                            max-width: get-mobile(430px);
                        }

                        a {
                            background-size: get-mobile(118px) get-mobile(59px);
                            width: get-mobile(118px);
                            height: get-mobile(59px);
                            margin: get-mobile(30px) auto get-mobile(-30px) auto;
                        }
                    }
                }

                &.amount {
                    padding: get-mobile(53px) 0 get-mobile(42px) 0;

                    h3 {
                        font-size: get-mobile(80px);
                        padding: 0 0 get-mobile(50px) 0;

                        &:after {
                            font-size: get-mobile(40px);
                        }

                        span {
                            font-size: get-mobile(35px);
                        }
                    }

                    h4 {
                        font-size: get-mobile(24px);
                    }
                }

                &.description {
                    .inner-wrapper {
                        max-width: 100%;
                        padding: 0 get-mobile(50px);

                        h3 {
                            font-size: get-mobile(65px);
                            max-width: get-mobile(450px);
                            padding: 0 0 get-mobile(50px) 0;

                            &:after {
                                font-size: get-mobile(40px);
                            }
                        }

                        h4 {
                            font-size: get-mobile(35px);
                            padding: get-mobile(125px) 0 0 0;
                        }

                        p {
                            font-size: get-mobile(35px);
                            max-width: 100%;
                            padding: get-mobile(80px) 0 get-mobile(130px) 0;
                        }
                    }
                }

                &.participate {
                    background: url('../images/participate-background-mobile.png') bottom center no-repeat;
                    background-size: get-mobile(640px) get-mobile(409px);

                    .inner-wrapper {
                        padding: 0 get-mobile(50px) get-mobile(420px) get-mobile(50px);

                        h3 {
                            font-size: get-mobile(60px);
                            padding: get-mobile(130px) 0 get-mobile(70px) 0;

                            &:after {
                                font-size: get-mobile(40px);
                            }
                        }

                        .rows {
                            padding: get-mobile(30px) 0 get-mobile(100px) 0;

                            .row {
                                font-size: get-mobile(60px);
                                margin: get-mobile(100px) 0 0 0;

                                .image {
                                    padding: get-mobile(30px) 0;

                                    img {
                                        max-width: get-mobile(217px);
                                    }
                                }

                                .description {
                                    font-size: get-mobile(35px);
                                }
                            }
                        }

                        a {
                            font-size: get-mobile(36px);
                            width: get-mobile(467px);
                            height: get-mobile(90px);
                            border-radius: get-mobile(45px);
                            padding: get-mobile(26px) 0;
                        }
                    }
                }

                &.about {
                    .inner-wrapper {
                        padding: get-mobile(90px) 0 0 0;

                        .flex {
                            .content {
                                width: 100%;
                                max-width: 880px;

                                h3 {
                                    font-size: get-mobile(45px);
                                    padding: 0 0 get-mobile(70px) 0;

                                    &:after {
                                        font-size: get-mobile(40px);
                                    }
                                }

                                p {
                                    font-size: get-mobile(35px);
                                    padding: get-mobile(40px) 0 0 0;

                                    &:last-of-type {
                                        padding: get-mobile(40px) 0 get-mobile(70px) 0;
                                    }
                                }

                                img {
                                    width: get-mobile(366px);
                                }
                            }
                        }
                    }
                }

                &.stores {
                    .inner-wrapper {
                        padding: get-mobile(90px) 0 get-mobile(30px) 0;

                        h3 {
                            font-size: get-mobile(60px);
                            padding: 0 get-mobile(50px) get-mobile(70px) get-mobile(50px);
                            max-width: 100%;

                            &:after {
                                font-size: get-mobile(40px);
                            }
                        }

                        .flex {
                            padding: get-mobile(45px) 0 0 0;
                        }
                    }
                }
            }
        }
    }
}
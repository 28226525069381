header {
    &.page-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 62px;
        background-color: $white;
        z-index: 50;

        .inner-wrapper {
            section {
                &.header-section {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    align-content: center;
                    position: relative;
                    width: 100%;
                    max-width: 1592px;
                    margin: 0 auto;
                    height: auto;

                    .logo {
                        width: 423px;
                        height: 64px;
                        z-index: 1;

                        a {
                            display: block;
                            background: url('../images/logo-nedelya-header.png') bottom center no-repeat;
                            background-size: 423px 135px;
                            width: 423px;
                            height: 135px;
                        }
                    }

                    ul {
                        &.main-nav {
                            list-style: none;

                            &.opened {
                                display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                justify-content: center;
                                align-items: center;
                                align-content: center;
                                position: absolute;
                                top: 92px;
                                left: 0;
                                width: 100%;
                                background-color: $orange-2;
                                height: 450px;

                                li {
                                    display: block;
                                    text-align: center;
                                    margin: 0;
                                    width: 100%;

                                    a {
                                        @include font-size(35px);

                                        display: block;
                                        width: 100%;
                                        padding: 20px 0;
                                        color: $white;
                                        font-weight: 400;
                                        font-family: $font-base-b;
                                    }
                                }
                            }

                            li {
                                display: inline-block;
                                margin: 0 0 0 25px;

                                &:first-child {
                                    margin: 0;
                                }

                                a {
                                    @include font-size(20px);

                                    text-shadow: 2px 3px 0 rgba($red-3, 0.18);
                                    color: $black;
                                    font-family: $font-base-b;
                                    font-weight: 400;
                                    text-transform: uppercase;
                                    display: block;
                                }
                            }
                        }
                    }

                    .buttons {
                        display: none;
                        position: relative;
                        padding: 0 30px 0 0;

                        .hamburger {
                            cursor: pointer;

                            .hamburger-1,
                            .hamburger-2,
                            .hamburger-3 {
                                width: 54px;
                                height: 8px;
                                border-radius: 5px;
                                background-color: $black;
                            }

                            .hamburger-2 {
                                margin: 8px 0;
                            }
                        }

                        .close {
                            display: none;
                            cursor: pointer;
                            position: relative;

                            .hide {
                                width: 54px;
                                height: 40px;

                                &:before,
                                &:after {
                                    height: 46px;
                                    width: 6px;
                                    content: '';
                                    position: absolute;
                                    top: -3px;
                                    left: 24px;
                                    background-color: $black;
                                    border-radius: 5px;
                                }

                                &:before {
                                    transform: rotate(45deg);
                                }

                                &:after {
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
main {
    &.content {
        section {
            &.top {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;
                align-content: center;
                width: 100%;
                height: 1080px;
                text-align: center;
                background: url('../images/top-background.jpg') center center no-repeat;
                background-size: 1920px 1080px;

                .inner-wrapper {
                    max-width: 770px;
                    padding: 0 50px;

                    h1,
                    h2 {
                        white-space: pre-line;
                        width: 100%;
                        max-width: 700px;
                        margin: 0 auto;
                    }

                    h1 {
                        @include font-size(60px);

                        color: $red;
                        text-transform: uppercase;
                        text-shadow: 2px 3px 0 rgba($red-3, 0.72);
                        font-weight: 400;
                        font-family: $font-base-b;
                        position: relative;
                        padding: 50px 0;

                        &:before {
                            @include font-size(40px);

                            color: $red-2;
                            font-family: $font-secondary;
                            content: "\e902";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            transform: rotate(180deg);
                            text-shadow: initial;
                        }

                        &:after {
                            @include font-size(40px);

                            color: $red-2;
                            font-family: $font-secondary;
                            content: "\e902";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            text-shadow: initial;
                        }
                    }

                    h2 {
                        @include font-size(28px);

                        color: $red-2;
                        font-weight: bold;
                        font-family: $font-base;
                        white-space: pre-line;
                        padding: 25px 0 0 0;
                    }

                    a {
                        display: block;
                        background: url('../images/icon-arrow-header-red.png') center center no-repeat;
                        background-size: 56px 35px;
                        width: 56px;
                        height: 35px;
                        margin: 30px auto 20px auto;
                    }
                }
            }

            &.amount {
                background-color: $green;
                color: $white;
                text-align: center;
                padding: 53px 0 42px 0;

                h3 {
                    @include font-size(80px);

                    font-weight: 400;
                    font-family: $font-base-b;
                    text-transform: uppercase;
                    width: 100%;
                    max-width: 590px;
                    margin: 0 auto;
                    position: relative;
                    padding: 0 0 50px 0;

                    &:after {
                        @include font-size(40px);

                        font-family: $font-secondary;
                        content: "\e902";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        text-shadow: initial;
                    }

                    span {
                        @include font-size(35px);

                        font-weight: 400;
                        font-family: $font-base-b;
                    }
                }

                h4 {
                    @include font-size(24px);

                    font-weight: 400;
                    font-family: $font-base-b;
                    text-transform: uppercase;
                }
            }

            &.description {
                background-color: $red;
                color: $white;
                text-align: center;

                .inner-wrapper {
                    max-width: 960px;
                    padding: 0 50px;

                    h3 {
                        @include font-size(40px);

                        font-weight: 400;
                        font-family: $font-base-b;
                        text-transform: uppercase;
                        width: 100%;
                        max-width: 590px;
                        margin: 0 auto;
                        position: relative;
                        padding: 0 0 50px 0;

                        &:after {
                            @include font-size(40px);

                            font-family: $font-secondary;
                            content: "\e903";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            text-shadow: initial;
                        }
                    }

                    h4 {
                        @include font-size(24px);

                        font-weight: 400;
                        font-family: $font-base-b;
                        text-transform: uppercase;
                        padding: 55px 0 0 0;
                    }

                    p {
                        @include font-size(24px);

                        font-weight: 400;
                        font-family: $font-base-b;
                        text-transform: uppercase;
                        width: 100%;
                        max-width: 860px;
                        margin: 0 auto;
                        padding: 80px 0 100px 0;
                    }
                }
            }

            &.participate {
                text-align: center;
                color: $gray-2;
                background: url('../images/participate-background.png') bottom center no-repeat;
                background-size: 1920px 613px;

                .inner-wrapper {
                    max-width: 1380px;
                    padding: 0 50px 341px 50px;

                    h3 {
                        @include font-size(45px);

                        font-weight: 400;
                        font-family: $font-base-b;
                        text-transform: uppercase;
                        padding: 130px 0 70px 0;
                        position: relative;

                        &:after {
                            @include font-size(40px);

                            font-family: $font-secondary;
                            content: "\e902";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            text-shadow: initial;
                        }
                    }

                    .rows {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        align-content: stretch;
                        padding: 30px 0 50px 0;

                        .row {
                            @include font-size(37px);

                            font-weight: 400;
                            font-family: $font-base-b;
                            text-transform: uppercase;
                            width: 100%;
                            max-width: 280px;

                            .image {
                                padding: 30px 0;

                                img {
                                    display: block;
                                    margin: 0 auto;
                                    max-width: 142px;
                                }
                            }

                            .description {
                                @include font-size(24px);

                                white-space: pre-line;

                                span {
                                    color: $red;
                                }
                            }
                        }
                    }

                    a {
                        @include font-size(23px);

                        color: $white;
                        font-family: $font-base-b;
                        font-weight: 400;
                        text-transform: uppercase;
                        text-align: center;
                        width: 296px;
                        height: 57px;
                        box-shadow: 2px 3px 0 rgba($red-3, 0.72);
                        border-radius: 28px;
                        background-color: $red;
                        display: block;
                        margin: 0 auto;
                        padding: 16px 0;
                    }
                }
            }

            &.about {
                background-color: $red;
                color: $white;

                .inner-wrapper {
                    padding: 90px 0;

                    .flex {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        align-content: center;

                        .image {
                            width: 100%;
                            max-width: 960px;

                            img {
                                width: 100%;
                                display: block;
                            }
                        }

                        .content {
                            width: 100%;
                            max-width: 880px;
                            padding: 0 150px 0 0;

                            h3 {
                                @include font-size(45px);

                                font-weight: 400;
                                font-family: $font-base-b;
                                text-align: center;
                                text-transform: uppercase;
                                padding: 0 0 70px 0;
                                position: relative;

                                &:after {
                                    @include font-size(40px);

                                    font-family: $font-secondary;
                                    content: "\e902";
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;
                                    text-align: center;
                                    text-shadow: initial;
                                }
                            }

                            p {
                                @include font-size(30px);

                                font-weight: 400;
                                font-family: $font-base;
                                padding: 40px 0 0 0;

                                &:last-of-type {
                                    padding: 40px 0 70px 0;
                                }
                            }

                            img {
                                display: block;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }

            &.stores {
                color: $gray;

                .inner-wrapper {
                    padding: 90px 0 30px 0;

                    h3 {
                        @include font-size(45px);

                        color: $red;
                        font-weight: 400;
                        font-family: $font-base-b;
                        text-align: center;
                        text-transform: uppercase;
                        white-space: pre-line;
                        padding: 0 0 70px 0;
                        position: relative;

                        &:after {
                            @include font-size(40px);

                            font-family: $font-secondary;
                            content: "\e902";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            text-align: center;
                            text-shadow: initial;
                        }
                    }

                    .flex {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        align-content: center;
                        padding: 45px 0 0 0;

                        .map {
                            width: 100%;
                            max-width: 960px;

                            #g-map {
                                width: 100%;
                                height: 642px;
                                background-color: $gray;
                            }
                        }

                        .content {
                            width: 100%;
                            max-width: 880px;
                            padding: 0 150px 0 0;

                            .container {
                                margin: 45px 0 0 0;
                                height: 400px;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include desktop {
    footer {
        &.page-footer {
            .inner-wrapper {
                padding: 20px 20px;
            }
        }
    }
}

// @include tablet {}

// @include mobile {}

@include small-mobile {
    footer {
        &.page-footer {
            background-color: $orange;

            .inner-wrapper {
                flex-direction: column;
                justify-content: space-around;

                .terms {
                    margin: 30px 0;
                }

                .socials {
                    width: 66px;
                }
            }
        }
    }
}